import axios from "axios"
import router from '../router'

const content = {
	namespaced: true,
	state: {
		popVisible: false,
		services: [],
		dop: '',
		works: [],
		slider: [],
		advant: [],
		dynamic: null,
		menu: [],
		services: [],
		contacts: null,
		about: null,
		footer: [],
		dopblock: null,
		equipment: []
  	},
	mutations: {
		SET_ABOUT(state, about){
			state.about = about
		},
		SET_CONTACTS(state, contacts){
			state.contacts = contacts
		},
		SET_SERV(state, services){
			state.services = services
		},
		SET_DYNAMIC(state, dynamic){
			state.dynamic = dynamic
		},
		SET_ADVANT(state, advant){
			state.advant = advant
		},
		SET_POP(state){
            state.popVisible = !state.popVisible
        },
		SET_SERVICES(state, services){
			state.services = services
		},
		SET_DOP(state, dop){
			state.dop = dop
		},
		SET_WORKS(state, works){
			state.works = works
		},
		SET_SLIDER(state, slider){
			state.slider = slider
		},
		SET_MENU(state, menu){
			state.menu = menu
		},
		SET_FOOTER(state, footer){
			state.footer = footer
		},
		SET_ADD(state, dopblock){
			state.dopblock = dopblock
		},
		SET_EQ(state, equipment){
			state.equipment = equipment
		}
	},
	actions: {
		loadAdd({commit}){
			axios.get('https://smpro78.ru/wp-json/gabino/v1/get/dopblock')
			.then(res =>{
				commit("SET_ADD", res.data.data)
			})
		},
		loadMenu({commit}){
			axios.get('https://smpro78.ru/wp-json/gabino/v1/get/menu/main')
			.then(res =>{
				commit("SET_MENU", res.data)
			})
		},
		loadServ({commit}){
			axios.get('https://smpro78.ru/wp-json/gabino/v1/get/services')
			.then(res =>{
				commit("SET_SERV", res.data.data)
			})
		},
		loadSlider({commit}){
			axios.get('https://smpro78.ru/wp-json/gabino/v1/get/slider')
			.then(res =>{
				commit("SET_SLIDER", res.data.data)
			})
		},
		changePop({commit}){
            commit("SET_POP")
        },
		loadServices({commit}){
			axios.get('https://smpro78.ru/wp-json/gabino/v1/get/tables')
			.then(res =>{
				commit("SET_SERVICES", res.data)
			})
		},
		loadDop({commit}){
			axios.get('https://smpro78.ru/wp-json/gabino/v1/get/doptext')
			.then(res =>{
				commit("SET_DOP", res.data.data)
			})
		},
		loadWorks({commit}){
			axios.get('https://smpro78.ru/wp-json/gabino/v1/get/our-works')
			.then(res =>{
				commit("SET_WORKS", res.data.data)
			})
		},
		loadAdvant({commit}){
			axios.get('https://smpro78.ru/wp-json/gabino/v1/get/advant')
			.then(res =>{
				commit("SET_ADVANT", res.data.data)
			})
		},
		loadPage({commit, state}, url){
			state.dynamic = null
			axios.get(`https://smpro78.ru/wp-json/gabino/v1/get/page/${url}`)
			.then(res =>{
				commit("SET_DYNAMIC", res.data.data)
			})
			.catch(err =>{
				if(err.response.status == 404){
					router.push('/404')
				}
			})
		},
		loadContacts({commit}){
			axios.get('https://smpro78.ru/wp-json/gabino/v1/get/contacts')
			.then(res =>{
				commit("SET_CONTACTS", res.data.data)
			})
		},
		loadAbout({commit}){
			axios.get('https://smpro78.ru/wp-json/gabino/v1/get/about')
			.then(res =>{
				commit("SET_ABOUT", res.data.data)
			})
		},
		loadFooter({commit}){
			axios.get('https://smpro78.ru/wp-json/gabino/v1/get/menu/footer')
			.then(res =>{
				commit("SET_FOOTER", res.data)
			})
		},
		loadEq({commit}){
			axios.get('https://smpro78.ru/wp-json/gabino/v1/get/oborudovanie')
			.then(res =>{
				commit("SET_EQ", res.data.data)
			})
		}
	},
	getters: {
		getEquipment(state){
			return state.equipment
		},
		getDopblock(state){
			return state.dopblock
		},
		getFooter(state){
			return state.footer
		},
		getAbout(state){
			return state.about
		},
		getContacts(state){
			return state.contacts
		},
		getServices(state){
			return state.services
		},
		getDynamic(state){
			return state.dynamic
		},
  		getPopVisible(state){
            return state.popVisible
        },
		getServices(state){
			return state.services
		},
		getDop(state){
			return state.dop
		},
		getWorks(state){
			return state.works
		},
		getSlider(state){
			return state.slider
		},
		getAdvant(state){
			return state.advant
		},
		getMenu(state){
			return state.menu
		},
		getSingle: (state) => (slug) => {
			return state.services.find(item => item.slug === slug)
		},
	}
}

export default content


