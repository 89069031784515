<template>
    <section id="serv">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Наши работы</h2>
                </div>
                <div class="col-lg-4" v-for="(img, i) in works" :key="i">
                    <div class="work-box" :style="{'background-image': 'url(' + img + ')'}"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    computed: {
        ...mapGetters({
            works: "content/getWorks"
        })
    },
}
</script>

<style scoped>
.work-box{
    margin-bottom: 30px;
}
</style>