<template>
    <section id="serv" v-if="equipment.length">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Перечень оборудования</h2>
                    <!-- <pre>{{equipment}}</pre> -->
                </div>
                <div class="col-lg-4 wow fadeInUp" v-for="(item, i) in equipment" :key="i">
                    <div class="eq-box">
                        <img :src="item.image" alt="">
                    </div>
                    <h4>{{item.title}}</h4>
                    <p class="black-txt mb-40" v-html="item.content"></p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            equipment: 'content/getEquipment'
        })
    },
    data(){
        return{
            servs: [
                {
                    name: 'Лазерный труборез Hans laser pro1',
                    txt: 'Комплекс Tube Pro I, специально создан для чистого раскроя труб толщиной до 10 мм длиной до 6.7 м для промышленных предприятий. Благодаря высокой точности обработки и скорости до 100 м/мин ЧПУ станок занимает одно из лучших мест среди лазерных труборезов.',
                    img: require('../assets/img/f1.png')
                },
                {
                    name: 'Установка для сварки продольных швов swd smart edition',
                    txt: 'Установки для сварки продольных швов серии SWD используются для автоматической дуговой сварки труб, коробов, листов и обечаек, а также широко применяются для сварки воздуховодов и дымоходов.',
                    img: require('../assets/img/f2.png')
                },
                {
                    name: 'Координатно-пробивной пресс Prima Power Shear Genius',
                    txt: 'Основным элементом станка является сервоэлектрическая технология, обеспечивающая экологически безопасное производство с пониженным энергопотреблением, экономией сырья и отсутствием отработанного масла.',
                    img: require('../assets/img/f3.png')
                },
                {
                    name: 'Гибочные станки safan',
                    txt: 'В своей работе SafanDarley ориентируется на новейшие тенденции в производстве листогибочного оборудования и гильотинных ножниц. Компания ведет отсчет своей истории с 1960 года. ',
                    img: require('../assets/img/f4.png')
                },
                {
                    name: 'Панелегиб salvagnini',
                    txt: 'Панелегиб Salvagnini совершенен вплоть до мелочей, делая невозможное возможным. Компания Salvagnini разработала систему по цене, доступной даже тем, кто до сегодняшнего дня мог только мечтать о гибке.',
                    img: require('../assets/img/f5.png')
                },
                {
                    name: 'Гибочный станок prima power',
                    txt: 'В панелегибах обрабатываемая листовая заготовка неподвижно закреплена, а гибка выполнятся гибочными траверсами. Благодаря этому они способны создавать различные изгибы, осуществляя гибку вверх и вниз.',
                    img: require('../assets/img/f6.png')
                },
                {
                    name: 'Лазерная резка Trumpf TruLaser 2030',
                    txt: 'Оптоволоконные лазерные станки для резки листового металла используются на производствах для раскроя листовой легированной, нержавеющей, углеродистой и пружинной стали, а также алюминия, меди или металла с покрытием.',
                    img: require('../assets/img/f7.png')
                },
            ]
        }
    }
}
</script>