<template>
    <section id="submit">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="submit-box text-center">
                        <div class="cross" @click="closePop">×</div>
                        <h2>Связаться с нами</h2>
                        <div class="sub">Задать вопрос и оставить заявку на оказание услуг</div>
                        <form @submit.prevent="submit">
                            <div class="row">
                                <div class="col-lg-4">
                                    <input type="text" placeholder="Ваше имя" v-model="cname" :class="{errorSubm : $v.cname.$dirty && !$v.cname.required}">
                                </div>
                                <div class="col-lg-4">
                                    <input type="text" placeholder="+7 ()" v-mask="'+7 (###) ###-##-##'" v-model="phone" :class="{errorSubm : ($v.phone.$dirty && !$v.phone.required) || ($v.phone.$dirty && !$v.phone.minLength)}">
                                </div>
                                <div class="col-lg-4">
                                    <input type="text" placeholder="Ваш email" v-model="email" :class="{errorSubm : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}">
                                </div>
                                <div class="col-lg-12">
                                    <textarea placeholder="Сообщение" v-model="text"></textarea>
                                </div>
                                <div class="col-lg-12">
                                    <button class="main-btn" type="submit">Отправить</button>
                                </div>

                                <div class="result success" v-if="result === true">Письмо успешно отправлено!</div>
                                <div class="result err" v-if="result === false">Ошибка отправки</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {required, email, minLength } from "vuelidate/lib/validators";
import axios from 'axios'

export default {
    methods: {
        closePop(){
            this.$emit('closePop')
        },
        submit(){
            if(this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

                let emailBody = {
                    cname: this.cname,
                    phone: this.tel,
                    email: this.email,
                    text: this.text,
                }

                var form = new FormData();

                for (var field in emailBody){
                    form.append(field, emailBody[field]);
                };

                axios
            	.post('https://smpro78.ru/wp-json/contact-form-7/v1/contact-forms/41/feedback', form)
                .then(res => {
                    this.cname = ''
                    this.phone = ''
                    this.email = ''
                    this.text = ''
                    this.$v.$reset();
                    this.result = true
                })
                .catch(err =>{
                    this.cname = ''
                    this.phone = ''
                    this.email = ''
                    this.text = ''
                    this.$v.$reset();
                    this.result = false
                })
        }
    },
    data(){
        return{
            cname: '',
            email: '',
            phone: '',
            text: '',
            result: null
        }
    },
    validations: {
        cname: {
            required
            },
        email:{
            required,
            email
        },
        phone: {
            required,
            minLength: minLength(18)
        },
    },
}
</script>

<style>
#submit{
    padding-bottom: 80px;
}
.result{
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
}
.success{
    color: aquamarine;
}
.err{
    color: #E56036;
}
</style>